import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./css/Startup1.css";

function Finacial1() {
  return (
    <div>
        <h2 className='text-center mt-5 wow bounceInLeft'>Financial Consultancy:</h2>
         <h3 className='text-center my-4 wow bounceInRight' data-wow-delay=".35s">A Brief Overview</h3>
         <p className='srtuppara text-center wow bounceInLeft'>
         Private equity funding is a form of investment that provides capital to established companies in exchange for ownership equity. It can help businesses accelerate growth, expand operations, make acquisitions, or restructure. Here's a short and crisp content on private equity funding:
         </p>

         <Container className='my-5'>
        <Row>
            
            <Col lg={4} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column">
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right"> Expertise:</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-3"> Expertise:</h3>
              <p className="snip__text text-left">
              Financial consultants are trained and qualified professionals with expertise in various aspects of finance, such as accounting, investments, taxation, insurance, and financial laws and regulations. They stay updated with the latest market trends, economic conditions, and financial products to provide informed advice to their clients..
              </p>
            </figcaption>
           
          </figure>
            </Col>
            <Col lg={4} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column">
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right"> Benefits:</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-3"> Benefits:</h3>
              <p className="snip__text text-left">
              Financial consultancy can provide numerous benefits, including improved financial planning, increased investment returns, better risk management, tax optimization, retirement planning, and estate planning. It can help clients make informed financial decisions, optimize their financial resources, and achieve their short-term and long-term financial goals
              </p>
            </figcaption>
           
          </figure>
            </Col>
            <Col lg={4} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column">
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right">Regulatory Compliance:</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-4">Regulatory Compliance:</h3>
              <p className="snip__text text-left">
              Financial consultants are often regulated by financial authorities or professional bodies, and they are required to adhere to ethical standards and comply with applicable laws and regulations. Clients should verify the credentials and regulatory compliance of a financial consultant before engaging their services.
              </p>
            </figcaption>
          </figure>
            </Col>
       
            <Col lg={4} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column my-4"> 
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right">Fee Structure:</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-4">Fee Structure:</h3>
              <p className="snip__text text-left">
              Financial consultants may charge fees in various ways, such as hourly rates, fixed fees, or a percentage of the assets under management. It's essential to understand the fee structure and any potential conflicts of interest before engaging a financial consultant..
              </p>
            </figcaption>
          </figure>
            </Col>
            <Col lg={4} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column my-4">
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right">Clientele:</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-3">Clientele:</h3>
              <p className="snip__text text-left my-4">
              Financial consultants serve a diverse clientele, including individuals, families, small businesses, large corporations, non-profit organizations, and government entities. They work closely with their clients to understand their unique financial needs and objectives, and provide customized solutions accordingly.
              </p>
            </figcaption>
          </figure>
            </Col>
            <Col lg={4} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column my-4">
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right">Scope of Services:</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left mb-3">Scope of Services:</h3>
              <p className="snip__text text-left">
              Financial consultants offer a wide range of services, including financial planning, investment management, risk management, retirement planning, tax planning, estate planning, and more. They assess a client's financial situation, goals, and risk tolerance to develop tailored strategies and recommendations to help them achieve their financial objectives.
              </p>
            </figcaption>
          </figure>
            </Col>
        </Row>
        <p className='intro text-left my-5'>In conclusion, financial consultancy is a professional service that offers expert advice and solutions for managing finances, investments, and overall financial well-being. It can provide valuable guidance to individuals, businesses, and organizations in achieving their financial goals and making informed financial decisions. However, it's crucial to carefully select a qualified and reputable financial consultant and thoroughly understand the scope of services, fee structure, and regulatory compliance before availing their services. Consulting with a financial advisor can be a prudent step towards achieving financial success.</p>
        <p className='intro text-left my-5'>Loans are financial instruments that allow individuals or businesses to borrow money from a lender, typically a financial institution, with the agreement to repay the borrowed amount, usually with interest, over a set period of time. Loans are used for a variety of purposes, including financing major purchases such as homes, cars, or education, funding business operations, consolidating debt, or covering unexpected expenses.</p>
      </Container>


      <Container className='my-5'>
        <Row>
            <Col lg={3} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column">
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right"> Types of Loans:</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-4">Types of Loans:</h3>
              <p className="snip__text text-left ">
              There are different types of loans, including personal loans, business loans, mortgage loans, auto loans, student loans, and more. Each type of loan has its own purpose, terms, and conditions.
              </p>
            </figcaption>
           
          </figure>
            </Col>
            <Col lg={3} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column">
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right">Repayment Terms:</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-4">Repayment Terms:</h3>
              <p className="snip__text text-left">
              Loans have specific repayment terms, which outline how long the borrower has to repay the loan and the amount of each installment. Repayment terms can range from a few months to several years, depending on the type of loan and the lender's policies.
              </p>
            </figcaption>
           
          </figure>
            </Col>
            <Col lg={3} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column">
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right">Collateral:</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-4">Collateral:</h3>
              <p className="snip__text text-left">
              Some loans require collateral, which is an asset that the borrower pledges to the lender as security for the loan. If the borrower fails to repay the loan, the lender may seize the collateral to recover the loan amount.
              </p>
            </figcaption>
           
          </figure>
            </Col>
            <Col lg={3} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column">
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right">Risks:</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-4">Risks:</h3>
              <p className="snip__text text-left">
              Borrowing money through loans comes with risks, including the obligation to repay the loan with interest, potential impact on credit score for late or missed payments, and the risk of losing collateral if the loan is not repaid.
              </p>
            </figcaption>
          </figure>
            </Col>
        </Row>
        <Row className='mx-5 p-5' >
            <Col lg={4} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column"> 
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right"> Fees and Charges:</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-4">Fees and Charges:</h3>
              <p className="snip__text text-left">
              Loans may come with additional fees and charges, such as origination fees, prepayment penalties, or late payment fees. Borrowers should carefully review and understand all the fees and charges associated with the loan.
              </p>
            </figcaption>
          </figure>
            </Col>
            <Col lg={4} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column">
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right"> Credit-worthiness:</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-4"> Credit-worthiness:</h3>
              <p className="snip__text text-left">
              Lenders assess borrowers' creditworthiness before approving a loan. Creditworthiness is determined by factors such as credit score, income, employment history, and existing debts. Borrowers with higher credit scores and stable income are more likely to get approved for loans with favorable terms.
              </p>
            </figcaption>
          </figure>
            </Col>
            <Col lg={4} md={12} sm={12} className="d-flex justify-content-center align-items-center flex-column">
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right">Interest Rates:</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-4">Interest Rates:</h3>
              <p className="snip__text text-left">
              Loans come with interest rates, which are the cost of borrowing money. Interest rates can be fixed, meaning they remain the same over the loan term, or variable, meaning they can change based on market conditions.
              </p>
            </figcaption>
          </figure>
            </Col>
        </Row>
        <p className='intro text-left my-5'>
        In summary, loans are financial tools that allow individuals or businesses to borrow money for various purposes. Borrowers should carefully consider the type of loan, interest rates, repayment terms, creditworthiness, collateral, fees, and risks associated with loans before making any decisions. It's important to review and understand the loan agreement thoroughly and seek professional advice if needed.
        </p>
      </Container>
    </div>
  )
}

export default Finacial1