import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./css/Startup1.css";

function GovrtLoan1() {
  return (
    <div>
       <h3 className='text-left hdgstrup wow bounceInLeft'>Government Loan Schemes</h3>
        <p className='srtuppara wow bounceInRight intro' data-wow-delay=".35s">
          The government has initiated a few loan schemes to provide collateral-free debt to aspiring entrepreneurs and help them gain
          access to low-cost capital such as the Startup India Seed Fund Scheme and SIDBI Fund of Funds. A list of government schemes can be
          found here
        </p>

        <h4 className=' txt-blod text-left wow bounceInLeft'>MSME Loans</h4>
        <p className='txt-normal text-left wow bounceInRight intro'>The word MSME means MICRO SMALL MEDIUM ENTERPRISES Where every small business is eligible for loan</p>

        <h4 className=' txt-blod text-left wow bounceInLeft'>What is MSME and its purpose?</h4>
        <p className='txt-normal text-left wow bounceInRight intro'>The Micro; Small and Medium Enterprises Development (MSMED) Act was notified in 2006 to address
          policy issues affecting MSMEs as well as the coverage and investment ceiling of the sector. The Act seeks to facilitate the development
          of these enterprises as also enhance their competitiveness.</p>

        <h4 className=' txt-blod text-left  wow bounceInLeft'>Who falls under MSME?</h4>
        <p className='txt-normal text-left wow bounceInRight intro'>Micro, Small and Medium Enterprises, also known as MSME, refers to those establishments where the
          funding in the machinery and the plant does not exceed Rs. 50 crores and the Annual turnover is below Rs. 250 crores. The Government of
          India has a Ministry, namely the Ministry of Micro, Small and Medium Enterprises</p>

        <h4 className=' txt-blod text-left  wow bounceInLeft'>What is tax benefit for MSME?</h4>
        <p className='txt-normal text-left wow bounceInRight intro'>Reduced Tax Rates: MSMEs with a turnover of up to ₹50 Crores can avail of a reduced tax rate of
          25% under Section 115BA of the Income Tax Act. GST Benefits: MSMEs with a turnover of up to ₹1.5 Crores can avail of the Composition
          Scheme under the Goods and Services Tax (GST) regime.</p>

        <h4 className=' txt-blod text-left  wow bounceInLeft'>What is MSME limit?</h4>
        <p className='txt-normal text-left wow bounceInRight intro'>Not more than Rs.50 crore and Annual Turnover ; not more than Rs. 250 crore. New definition of MSME,
          View Notification. Website Content Owned and Managed by Ministry of Micro, Small & Medium Enterprises, Government of India Designed, Developed
          and Hosted by National Informatics Centre( NIC )</p>

        <h4 className=' txt-blod text-left  wow bounceInLeft'>Title: "Empowering Small Businesses with MSME Loans"</h4>
        <p className='txt-normal text-left wow bounceInRight intro'>
          Small businesses are the backbone of the economy, and at [Your Company Name], we understand the importance of providing financial support
          to Micro, Small, and Medium Enterprises (MSMEs). Our MSME loans are designed to empower small businesses and help them thrive in today's
          competitive market.
        </p>

        <Container>
        <Row>
        <Col lg={3} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column">
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right"> CGTMSE Loan :</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-5"> CGTMSE Loan :</h3>
              <p className="snip__text text-left">
              1. Micro Enterprises <br /> <br />

2. Women Entrepreneurs/ MSEs / SC/ST Entrepreneurs 
<br /> <br />
3. All other category of borrowers 

              </p>
            </figcaption>
           
          </figure>
            </Col>
            <Col lg={3} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column">
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right">MUDRA Loan:</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-4"> MUDRA Loan:</h3>
              <p className="snip__text text-left">
              With an objective to promote entrepreneurship among the new generation aspiring youth, different products of MUDRA loans are  'Shishu', 'Kishore' and 'Tarun' to meet requirements of different sectors / business activities as well as business/ entrepreneur segments.
              </p>
            </figcaption>
           
          </figure>
            </Col>
            
            <Col lg={3} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column">
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right">Quick and Hassle-Free:</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-3"> Quick and Hassle-Free:</h3>
              <p className="snip__text text-left">
              We understand that small businesses need prompt access to capital. Our MSME loans come with a streamlined application process, minimal documentation, and quick approval, ensuring that you can get the funds you need without unnecessary delays.
              </p>
            </figcaption>
           
          </figure>
            </Col>
            <Col lg={3} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column">
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right">Supporting Growth:</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-4">Supporting Growth:</h3>
              <p className="snip__text text-left">
              Our MSME loans are not just about financial assistance; they are also about supporting the growth of your business. With our expertise and experience, we provide guidance and advice to help you make informed decisions and drive your business towards success.
              </p>
            </figcaption>
           
          </figure>
            </Col>
            <Col lg={3} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column">
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right">Partnering with 
Small Businesses:</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-4">Partnering with 
Small Businesses:</h3>
              <p className="snip__text text-left">
              We believe in building long-term relationships with our customers. Our MSME loans are not just a one-time transaction; they are a partnership towards your success. We strive to be your trusted financial partner, providing ongoing support to help your business thrive
              </p>
            </figcaption>
          </figure>
            </Col>
       
            <Col lg={3} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column my-4"> 
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right">Easy Repayment:</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-4">Easy Repayment:</h3>
              <p className="snip__text text-left">
              We understand the cash flow challenges that small businesses face, and our MSME loans come with easy repayment options. You can choose from flexible repayment tenures that suit your business cycle, making it convenient for you to repay the loan without affecting your business operations.
              </p>
            </figcaption>
          </figure>
            </Col>
            <Col lg={3} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column my-4">
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right">Competitive Interest 
Rates:</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-3">Competitive Interest 
Rates:</h3>
              <p className="snip__text text-left">
              We offer competitive interest rates on our MSME loans, making them affordable and cost-effective for small businesses. Our transparent pricing and flexible repayment options ensure that you can manage your loan comfortably without putting excessive strain on your cash flow.
              </p>
            </figcaption>
          </figure>
            </Col>
            <Col lg={3} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column my-4">
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right"> Tailored for Your Needs:</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-4">Tailored for Your Needs:</h3>
              <p className="snip__text text-left">
              Our MSME loans are customized to meet the unique requirements of small businesses. Whether you need funds for working capital, expansion, or equipment purchase, we have flexible loan options to suit your specific needs.
              </p>
            </figcaption>
          </figure>
            </Col>
        </Row>
        <p className='intro text-left my-5'>*It's important to note that not all startups go through every stage of funding, and the funding journey can vary greatly depending on the startup's business model, growth trajectory, and funding needs. Each funding round typically involves different levels of due diligence, valuation, and investor expectations. Founders should carefully consider their funding strategy, dilution of ownership, and long-term goals when raising capital at different stages of their startup's growth.</p>
      </Container>
    </div>
  )
}

export default GovrtLoan1
