import React from 'react'
import './css/services.css'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import first from './images/first.png'
import sec from './images/sec.png'
import third from './images/third.png'
import fourth from './images/fourth.png'
import fifth from './images/fifth.png'
import sixth from './images/sixth.png'
export default function Services() {
  return (
    <div className='container'>
      <Container className='mrgtp mt-5'>
        <div className='row d-flex justify-content-center'>
        <h1 className='fw-bolder servis-h1'>Our Services</h1>
         
          <div className='col-lg-4 col-md-4 col-sm-12 mt-5 d-flex justify-content-center'>
            <Link to="/govetLoas" target="_blank"><img src={sec} alt='' className='imghloan' /></Link>
          </div>
          <div className='col-lg-4 col-md-4 col-sm-12 mt-5 d-flex justify-content-center'>
          <Link to="/strupFinding" target="_blank"><img src={third} alt='' className='imghloan' /></Link>
          </div>
          <div className='col-lg-4 col-md-4 col-sm-12 mt-5 d-flex justify-content-center'>
          <Link to="/privindingEquity" target="_blank"><img src={fourth} alt='' className='imghloan' /></Link>
          </div>
        </div>
      </Container>

      <Container>
        <div className='row d-flex justify-content-center'>
        
          <div className='col-lg-4 col-md-4 col-sm-12 mt-5 d-flex justify-content-center'>
          <Link to="/overDraft" target="_blank"><img src={fifth} alt='' className='imghloan' /></Link>
          </div>
          <div className='col-lg-4 col-md-4 col-sm-12 mt-5 d-flex justify-content-center'>
          <Link to="/finacial" target="_blank"><img src={sixth} alt='' className='imghloan' /></Link>
          </div>
          <div className='col-lg-4 col-md-4 col-sm-12 mt-5 d-flex justify-content-center' >
          <Link to="/startup" target="_blank" ><img src={first} alt=''  className='imghloan'/></Link>
        </div>
          </div>

      </Container>
      </div>
  )
}
