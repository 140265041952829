import { React, useState } from 'react'
import { Pie } from 'react-chartjs-2';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useNavigate } from 'react-router-dom';
import emibg from '../components/images/emibg.png'
import { Container } from 'react-bootstrap';
ChartJS.register(ArcElement, Tooltip, Legend);

const LoanCalculator = () => {

    const style = {
        hover: {
            color: "black",
            fontSize: "20px",
            fontWeight: "600",
            cursor: "pointer",
            padding: "10px"
        }
    }
    const [loanAmount, setloanAmount] = useState()
    const [interest, setInterest] = useState()
    const [tenure, setTenture] = useState()
    const nav = useNavigate()

    let Tenure = (tenure) * 12;
    let rate = (interest) / 12 / 100;

    let emi = Math.floor(((loanAmount * rate * (1 + rate) ** Tenure) / (((1 + rate) ** Tenure) - 1)));

    let total = emi * Tenure; // total amount to be paid including interest
    let Interest = Math.floor(total - loanAmount)
    const data = {
        labels: ["Principal Amount:", "Total Amount:"],
        datasets: [
            {
                label: "",
                data: [loanAmount, Interest],
                backgroundColor: [
                    "#25d366",
                    "#007bff",
                ],

                borderWidth: 1,
                offset: 10
            }
        ]
    };
    return (
        <>
            <div className='container mt-5' style={{ backgroundImage: `url(${emibg})` }}>
                <div className='d-flex justify-content-start mb-3'>
                </div>
                <div className="input-group d-flex justify-content-start row mb-4">
                    <label className='col-lg-3 col-md-4 col-sm-12 text-end'> Loan Amount</label>
                    <div className='d-flex col-lg-6 col-md-8 col-sm-12'>
                        <input type="number" onChange={(e) => setloanAmount(e.target.value)} className="form-control" placeholder="Enter Loan Amount" />
                        <div className="input-group-append">
                            <button className="btn btn-secondary" type="button">
                                <i className="fa fa-rupee"></i>
                            </button>
                        </div>
                    </div>

                </div>
                <Container><ProgressBar className='mt-4' variant="warning" now={loanAmount} max={20000000} />
                </Container>
                <div className="input-group d-flex justify-content-start row mb-4 mt-4">
                    <span className='col-lg-3 col-md-4 col-sm-12 text-end'>Interest Rate</span>
                    <div className='d-flex col-lg-6 col-md-8 col-sm-12'>
                        <input type="number" onChange={(e) => setInterest(e.target.value)} className="form-control" placeholder="Enter Interest Rate" />
                        <div className="input-group-append">
                            <button className="btn btn-secondary" type="button">%
                                <i className="bi bi-percent"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <Container><ProgressBar variant="warning" className='mt-4' now={interest} /></Container>
                <div className="input-group d-flex justify-content-start row mb-4 mt-4">
                    <span className='col-lg-3 col-md-4 col-sm-12 text-end'>Loan Tenure</span>
                    <div className='d-flex  col-lg-6 col-md-8 col-sm-12 '>
                        <input type="number" onChange={(e) => setTenture(e.target.value)} className="form-control" placeholder="Loan Tenure" />

                        <div className="input-group-append ">
                            <button className="btn btn-secondary" type="button">
                                Month
                            </button>
                        </div>
                    </div>
                </div>
                <Container><ProgressBar variant="warning" className='mt-4' now={tenure} />
                </Container>
                <div className='row' style={{ background: 'white', margin: '50px 50px 0' }}>
                    <div className='d-flex flex-column col-lg-6 col-md-6 col-sm-12 mt-5'>
                        <div class="d-flex flex-column  mt-4 mb-3">
                            <span className='text-center font-weight-bold mb-3'>Loan EMI</span>
                            <span className='text-center mb-3'>{emi ? emi : ""}</span>
                        </div>
                        <div class="d-flex flex-column  mt-4 mb-3">
                            <span className='text-center font-weight-bold mb-3'>Total Interest Payable</span>
                            <span className='text-center mb-2'>{Interest ? Interest : ""}</span>
                        </div>
                        <div class="d-flex flex-column  mt-4 mb-3">
                            <span className='text-center font-weight-bold mb-3'>Total Payment
                                (Principal + Interest)</span>
                            <span className='text-center mb-2'>{total ? total : ""}</span>
                        </div>
                    </div>
                    <div className="pieGraph col-lg-6 col-md-6 col-sm-12 mt-3 d-flex justify-content-center mb-3 ">
                        <Pie width="300px" data={data} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoanCalculator;
