import React, { Component } from "react";
import AboutSection from './AboutSection'
import './css/about.css'
import WOW from "wowjs";
import About3 from './About3'
class About extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }
  render() {
    return (
      <div className="container">
        <About3/>

        <AboutSection/>
      </div>

    )
  }
}
export default About;

