import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./css/Startup1.css";
// import "./css/StartUp2.css";


function PrivateEquity1() {
  return (
    <div>
      <h2 className='text-center mt-5 wow bounceInLeft'>Private Equity Funding:</h2>
         <h4 className='text-center my-4 wow bounceInRight' data-wow-delay=".35s">Unlocking Growth for Businesses</h4>
         <p className='srtuppara text-center wow bounceInLeft'>
            Private equity funding is a form of investment that provides capital to established companies in exchange for ownership equity.
            It can help businesses accelerate growth, expand operations, make acquisitions, or restructure. Here's a short and crisp content on
            private equity funding:
         </p>

         <Container className='my-5'>
        <Row>
            <Col lg={3} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column">
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right"> Growth Capital:</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-4">Growth Capital:</h3>
              <p className="snip__text text-left ">
              Private equity firms provide growth capital to established businesses that have proven their business model and are looking to scale. This funding can be used to invest in new products, enter new markets, or expand operations, fueling business growth and value creation.
              </p>
            </figcaption>
           
          </figure>
            </Col>
            <Col lg={3} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column">
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right">Value Creation:</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-4">Value Creation:</h3>
              <p className="snip__text text-left">
              Private equity investors work closely with portfolio companies to implement operational improvements, optimize financial performance, and create value. This can involve improving corporate governance, enhancing operational efficiencies, optimizing supply chains, or implementing strategic initiatives to drive growth.
              </p>
            </figcaption>
           
          </figure>
            </Col>
            <Col lg={3} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column">
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right"> Long-Term Partnership:</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-3">Long-Term Partnership:</h3>
              <p className="snip__text text-left">
              Private equity firms often take a long-term approach to their investments, partnering with management teams to drive growth over several years. They bring not only capital but also strategic guidance, industry expertise, and operational support to help businesses achieve their full potential.
              </p>
            </figcaption>
           
          </figure>
            </Col>
            <Col lg={3} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column">
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right">Selective Investments:</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-3">Selective Investments:</h3>
              <p className="snip__text text-left">
              Private equity firms carefully evaluate investment opportunities and select companies with strong growth potential, robust business models, and experienced management teams. They conduct thorough due diligence before making investments to minimize risks and maximize returns.
              </p>
            </figcaption>
          </figure>
            </Col>
        </Row>
        <Row className='mx-5 p-5' >
            <Col lg={4} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column"> 
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right"> Risk and Return:</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-4">Risk and Return:</h3>
              <p className="snip__text text-left">
              Private equity funding involves higher risks compared to other forms of financing, as it requires giving up ownership equity and often involves operational changes. However, it also offers the potential for higher returns, as private equity firms aim to create value and generate significant profits upon exit.
              </p>
            </figcaption>
          </figure>
            </Col>
            <Col lg={4} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column">
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right"> Exit Strategies:</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-4">Exit Strategies:</h3>
              <p className="snip__text text-left">
              Private equity firms typically have a defined exit strategy to realize returns on their investments. This may include taking the company public through an initial public offering (IPO), selling the company to a strategic buyer, or merging with another company.
              </p>
            </figcaption>
          </figure>
            </Col>
            <Col lg={4} md={12} sm={12} className="d-flex justify-content-center align-items-center flex-column">
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right"> Buyouts:</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-4">Buyouts:</h3>
              <p className="snip__text text-left">
              Private equity buyouts involve the acquisition of a controlling stake in a company to take it private, delisting it from public stock exchanges. Private equity firms typically seek to improve the company's operations, implement strategic initiatives, and create value through operational efficiencies or cost savings.
              </p>
            </figcaption>
          </figure>
            </Col>
        </Row>
        <p className='intro text-left'>
 In conclusion, private equity funding provides established companies with capital and strategic support to accelerate growth, create value, and achieve their full potential. It involves a partnership between private equity firms and businesses, with a focus on long-term value creation and well-defined exit strategies
        </p>
      </Container>
    </div>
  )
}

export default PrivateEquity1
