import img1 from "../../images/Rectangle 157.png"
import img2 from "../../images/Rectangle 159.png";
import img3 from "../../images/Rectangle 165.png";
import img4 from "../../images/Rectangle 170.png";
import img5 from "../../images/Rectangle 168.png";
import img6 from "../../images/Rectangle 161.png";
import img7 from "../../images/Rectangle 163.png";




export const servicesData = [
  // {
  //   serviceImg: img1,
  //   serviceName: "Credit Guarantee Loans",
  //   serviceDetail:
  //     "Credit Guarantee Fund Trust for Micro and Small Enterprises",
  // },
  {
    serviceImg: img2,
    serviceName: "Start-up Funding",
    serviceDetail:
      "Credit Guarantee Fund Trust for Micro and Small Enterprises",
  },
  {
    serviceImg: img3,
    serviceName: "MSME Loans",
    serviceDetail:
      "Credit Guarantee Fund Trust for Micro and Small Enterprises",
  },
  // {
  //   serviceImg: img1,
  //   serviceName: "Credit Guarantee Loans",
  //   serviceDetail:
  //     "Credit Guarantee Fund Trust for Micro and Small Enterprises",
  // },
  // {
  //   serviceImg: img2,
  //   serviceName: "Start-up Funding",
  //   serviceDetail:
  //     "Credit Guarantee Fund Trust for Micro and Small Enterprises",
  // },
  // {
  //   serviceImg: img3,
  //   serviceName: "MSME Loans",
  //   serviceDetail:
  //     "Credit Guarantee Fund Trust for Micro and Small Enterprises",
  // },
  {
    serviceImg: img4,
    serviceName: "CGTMSE",
    serviceDetail:
      " (Credit Guarantee Fund Trust for Micro and Small Enterprises) (Credit Guarantee Fund Trust for Micro and Small Enterprises)",
  },
  {
    serviceImg: img5,
    serviceName: "Mudra Loan",
    serviceDetail:
      "Credit Guarantee Fund Trust for Micro and Small Enterprises",
  },
  {
    serviceImg: img6,
    serviceName: "Private Equity Funding ",
    serviceDetail:
      "Credit Guarantee Fund Trust for Micro and Small Enterprises",
  },
  // {
  //   serviceImg: img7,
  //   serviceName: "MSME Loans",
  //   serviceDetail:
  //     "Credit Guarantee Fund Trust for Micro and Small Enterprises",
  // },
];
