import React, { Component } from "react";
import WOW from "wowjs";
import './css/blog.css';
import { Container, Row, Col, Card} from 'react-bootstrap'
// import blog1 from '../components/images/blog1.png'
// import Blog2 from '../components/images/Blog2.png'
// import Blog8 from '../components/images/Blog8.png'
// import Blog9 from '../images/blogimg/blog9.jpg'
// import Blog5 from '../components/images/Blog5.png'
// import blog2 from "../images/blogimg/blog2.png";
import Blog1 from "../images/blogimg/blog1.png"
import Blog2 from "../images/blogimg/blog2.png"
import Blog3 from "../images/blogimg/blog3.png"
import Blog4 from "../images/blogimg/blog4.png"
import Blog5 from "../images/blogimg/blog5.png"
import Blog6 from "../images/blogimg/blog6.png"
import Blog7 from "../images/blogimg/blog7.jpg"
import Blog8 from "../images/blogimg/blog8.jpg"
import Blog9 from "../images/blogimg/blog9.jpg"

import Testomonial2 from '../Components3/Testomonial2';
import blog5add from "../images/blog5add.png"
import blog4add from "../images/blog4add.png"
class Blog extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }
  render() {
    return (
      <div className="mb-5">

      <Container className='Blogs'>
        {/* <Row> */}
          {/* <Col lg={4} md={4} className='d-flex justify-content-center flex-column '>
            <h1 className='blg-h1 wow fadeInLeftBig '>BLOGS</h1>
            <a className="card-text"  href="https://www.linkedin.com/posts/venkatachalam-iyer_banking-finance-legal-activity-7043823599945752576-KJfK?utm_source=share&utm_medium=member_android">
            <Card data-wow-duration="1s"  className="wow fadeInLeftBig">
              <Card.Img variant="top" src={Blog5} style={{ height: "220px" }} />
              <Card.Body>
                <Card.Text className="text-center card-text ">
                RBI Unveils Exciting Cheque Bounce Rule Updates: Don't Miss Out on the Latest Banking Buzz.
                </Card.Text>
              </Card.Body>
            </Card>
            </a>
          </Col> */}
          {/* <Col lg={8} md={8} className='mt-4'> */}
            <Row>
              <Col lg={4} md={6} className='mb-4 d-flex justify-content-center'>
              <a className="card-text" href="https://web.whatsapp.com/">
              <Card data-wow-duration="2s"  className="wow wow fadeIn">
                  <Card.Img variant="top" src={Blog1} style={{ height: "220px"  }} />
                <Card.Body>
                <Card.Text className="text-center">
                RBI Unveils Exciting Cheque Bounce Rule Updates: Don't Miss Out on the Latest Banking Buzz.
                </Card.Text>
              </Card.Body>
                </Card>
                </a>
              </Col>
              <Col lg={4} md={6} className='mb-4 d-flex justify-content-center'>
              <a className="card-text" href="https://www.linkedin.com/posts/bridgeeasyconsultancy_entrepreneurs-funding-finance-activity-7071721404890570752--Glx/?utm_source=share&utm_medium=member_android">
              <Card data-wow-duration="2s"  className="wow fadeInDown">
                  <Card.Img variant="top" src={Blog8} style={{ height: "220px"  }} />
                  <Card.Body>
                    <Card.Text className="text-center">
                    Revolutionizing Finance for Entrepreneurs and MSMEs!
                    </Card.Text>
                  </Card.Body>
                </Card>
                </a>
              </Col>
              <Col lg={4} md={6} className='mb-4 d-flex justify-content-center'>
              <a className="card-text" href="https://www.linkedin.com/posts/venkatachalam-iyer_uniteconomics-investingtips-financialplanning-activity-7051416533536804864-YCXe?utm_source=share&utm_medium=member_android">
              <Card data-wow-duration="3s"  className="wow fadeInDown">
                  <Card.Img variant="top" src={Blog3} style={{ height: "220px"  }} />
                  <Card.Body>
                <Card.Text className="text-center">
                Good News for MSMEs....from 1st April 2023.
                </Card.Text>
              </Card.Body>
                </Card>
                </a>
              </Col>
              <Col lg={4} md={6} className='mb-4 d-flex justify-content-center'>
              <a className="card-text" href="https://www.linkedin.com/posts/venkatachalam-iyer_startups-finance-financialjourney-activity-7107253875937587200-Wnuw/?utm_source=share&utm_medium=member_desktop">
              <Card data-wow-duration="3s"  className="wow fadeInRight">
                  <Card.Img variant="top" src={Blog4} style={{ height: "220px"  }} />
                  <Card.Body>
                <Card.Text className="text-center">
                Financial Pain Points Every Indian Startup Should Know.
                </Card.Text>
              </Card.Body>
                </Card>
                </a>
              </Col>
              <Col lg={4} md={6} className='mb-4 d-flex justify-content-center'>
              <a className="card-text" href="https://www.linkedin.com/posts/venkatachalam-iyer_bain-india-vc-2022-activity-7111952176591101952-rsh9/?utm_source=share&utm_medium=member_desktop">
              <Card data-wow-duration="2s"  className="wow wow fadeIn">
                  <Card.Img variant="top" src={Blog5} style={{ height: "220px"  }} />
                <Card.Body>
                <Card.Text className="text-center">
                Bain India Venture Capital Report 2022 Highlights
                </Card.Text>
              </Card.Body>
                </Card>
                </a>
              </Col>
              <Col lg={4} md={6} className='mb-4 d-flex justify-content-center'>
              <a className="card-text" href="https://www.linkedin.com/posts/venkatachalam-iyer_working-capital-loan-activity-7099281304885600256-5kBA/?utm_source=share&utm_medium=member_desktop">
              <Card data-wow-duration="2s"  className="wow wow fadeIn">
                  <Card.Img variant="top" src={Blog6} style={{ height: "220px"  }} />
                <Card.Body>
                <Card.Text className="text-center">
                In the dynamic maze of entrepreneurship, making strategic moves is vital.
                </Card.Text>
              </Card.Body>
                </Card>
                </a>
              </Col>
              <Col lg={4} md={6} className='mb-4 d-flex justify-content-center'>
              <a className="card-text" href="https://www.linkedin.com/posts/venkatachalam-iyer_startups-finance-financialjourney-activity-7107253875937587200-Wnuw/?utm_source=share&utm_medium=member_desktop">
              <Card data-wow-duration="2s"  className="wow wow fadeIn">
                  <Card.Img variant="top" src={Blog7} style={{ height: "220px"  }} />
                <Card.Body>
                <Card.Text className="text-center">
                Financial Pain Points Every Indian Startup Should Know.
                </Card.Text>
              </Card.Body>
                </Card>
                </a>
              </Col>
              <Col lg={4} md={6} className='mb-4 d-flex justify-content-center'>
              <a className="card-text" href="https://www.linkedin.com/posts/venkatachalam-iyer_creditcards-finance-financegoals-activity-7027144482991079424-VK8D/?utm_source=share&utm_medium=member_android">
              <Card data-wow-duration="2s"  className="wow wow fadeIn">
                  <Card.Img variant="top" src={Blog2} style={{ height: "220px"  }} />
                <Card.Body>
                <Card.Text className="text-center">
                Getting authorized for a new credit card is a great experience.
                </Card.Text>
              </Card.Body>
                </Card>
                </a>
              </Col>
             
              <Col lg={4} md={6} className='mb-4 d-flex justify-content-center'>
              <a className="card-text" href="https://www.linkedin.com/posts/venkatachalam-iyer_finance-funding-startups-activity-7094184033475043328-oYpy/?utm_source=share&utm_medium=member_desktop">
              <Card data-wow-duration="2s"  className="wow wow fadeIn">
                  <Card.Img variant="top" src={Blog9} style={{ height: "220px"  }} />
                <Card.Body>
                <Card.Text className="text-center">
                understanding finance principles can fuel our journey and give us the capital boost we need to soar.
                </Card.Text>
              </Card.Body>
                </Card>
                </a>
              </Col>
            </Row>
          {/* </Col> */}
        {/* </Row> */}
      </Container>
      <a href="https://wa.me/8459851404?text=I%20need%20help%20with%20my%20finance/portfolio" class="floating" target="_blank">
      <i class="fa fa-whatsapp fab-icon"></i>
      </a>
      {/* <Testomonial2/> */}
          </div>
    )
   }
}
export default Blog;

