
import blog1 from "../../images/blogimg/blog1.png"
import blog2 from "../../images/blogimg/blog2.png"
import blog3 from "../../images/blogimg/blog3.png"
import blog4 from "../../images/blogimg/blog4.png"
import blog5 from "../../images/blogimg/blog5.png"
import blog6 from "../../images/blogimg/blog6.png"
import blog7 from "../../images/blogimg/blog7.jpg"
import blog8 from "../../images/blogimg/blog8.jpg"
import blog9 from "../../images/blogimg/blog9.jpg"

export const blogData = [
  {
    // blogImg: img1,
  blogImg :blog1,
    blogDetail:
      "RBI Unveils Exciting Cheque Bounce Rule Updates: Don't Miss Out on the Latest Banking Buzz.",
      links:"https://web.whatsapp.com/",
  },
  {
    // blogImg: img2,
    blogImg :blog8,
    blogDetail: 
      "Revolutionizing Finance for Entrepreneurs and MSMEs!",
      links:"https://www.linkedin.com/posts/bridgeeasyconsultancy_entrepreneurs-funding-finance-activity-7071721404890570752--Glx/?utm_source=share&utm_medium=member_android",
    
  },
  {
    // blogImg: img3,
    blogImg :blog3,

    blogDetail:
      "Good News for MSMEs....from 1st April 2023.",
      links:"https://www.linkedin.com/posts/venkatachalam-iyer_uniteconomics-investingtips-financialplanning-activity-7051416533536804864-YCXe?utm_source=share&utm_medium=member_android",
  },
  {
    // blogImg: img1 4,
    blogImg :blog4,
    blogDetail:
      "Financial Pain Points Every Indian Startup Should Know.",
      links:"https://www.linkedin.com/posts/venkatachalam-iyer_startups-finance-financialjourney-activity-7107253875937587200-Wnuw/?utm_source=share&utm_medium=member_desktop",
  },
 
  {
    // blogImg: img3 ,
    blogImg :blog5,
    blogDetail:
      "Bain India Venture Capital Report 2022 Highlights",
      links:"https://www.linkedin.com/posts/venkatachalam-iyer_bain-india-vc-2022-activity-7111952176591101952-rsh9/?utm_source=share&utm_medium=member_desktop",
  },
  {
    // blogImg: img4,
    blogImg :blog6,

    blogDetail:
      " In the dynamic maze of entrepreneurship, making strategic moves is vital.",
      links:"https://www.linkedin.com/posts/venkatachalam-iyer_working-capital-loan-activity-7099281304885600256-5kBA/?utm_source=share&utm_medium=member_desktop",
  },
  // {
  //   blogImg:blog2,

  //   blogDetail:
  //     "Getting authorized for a new credit card is a great experience.",
  //     links:"https://www.linkedin.com/posts/venkatachalam-iyer_creditcards-finance-financegoals-activity-7027144482991079424-VK8D?utm_source=share&utm_medium=member_android",
  // },
  {
    // blogImg: img6,
    blogImg :blog4,
    blogDetail: 
      "Financial Pain Points Every Indian Startup Should Know.",
      links:"https://www.linkedin.com/posts/venkatachalam-iyer_startups-finance-financialjourney-activity-7107253875937587200-Wnuw/?utm_source=share&utm_medium=member_desktop",
  },
  {
    blogImg :blog7,
    blogDetail: 
      "Driving this change?  ",
      links:"https://www.linkedin.com/posts/venkatachalam-iyer_hdfcmerger-leadership-finance-activity-7081859175126827008-iMRm/?utm_source=share&utm_medium=member_android",
  },
  {
    blogImg :blog2,
    blogDetail:
      "Getting authorized for a new credit card is a great experience.",
      links:"https://www.linkedin.com/posts/venkatachalam-iyer_creditcards-finance-financegoals-activity-7027144482991079424-VK8D/?utm_source=share&utm_medium=member_android",
  },
  {
    blogImg :blog9,
    blogDetail: 
      "understanding finance principles can fuel our journey and give us the capital boost we need to soar.",
      links:"https://www.linkedin.com/posts/venkatachalam-iyer_finance-funding-startups-activity-7094184033475043328-oYpy/?utm_source=share&utm_medium=member_desktop",
  },

];
