import "../assets/css/Home2.css";
import 'animate.css'; 
import React from "react";
const Home2 = () => (
  <section className='home-section' id='home'>
  <div className='gradient-wrapper'>

  </div>
  <div className='head-wrapper'>
<h2 className='home-head animate__animated animate__fadeInLeft'>"Finance the Unfinanced"</h2>
  </div>

  <div className='bottom-blocks mb-2'>
    <div className='first-block home-block' ></div>
    <div className='second-block home-block' style={{height:"70%"}}>
    
      <h3 className='home-blocks-head text-center'>MSME Loans</h3>
    </div>
    <div className='third-block home-block' style={{height:"70%"}}> <h3 className='home-blocks-head text-center'>Startup Fundng</h3></div>
    <div className='fourth-block home-block' style={{height:"70%"}}> <h3 className='home-blocks-head text-center'>Business Loans</h3></div>
    <div className='fifth-block home-block'></div>
  </div>
  
  <div  className="mobile-services d-lg-none d-md-none d-sm-block">
    <div className="container">
    <div className="row">
    <div className="col-sm-12">
    <h4></h4>
    </div>
    </div>
    </div>
  </div>

</section>
);

export default Home2;
