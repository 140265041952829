import './css/home.css'
import WOW from "wowjs";
import React, { Component } from "react";
import Home2 from '../Components3/Home2';
import  About2 from '../Components3/About2'
import Services2 from '../Components3/services/Services2';
import Testomonial2 from '../Components3/Testomonial2';
import Contact2 from "../Components3/Contact2";
import Blog2 from "../Components3/blogs/Blog2";
import Profile from "../Components3/profile/Profile"

class  Home extends Component{
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }
render() {  
  return (
    <>
      <div >
       <Home2/>
        <About2/>
        <Services2/>
        <Blog2/>
        <Testomonial2/>
     {/* <Profile/> */}
     <Contact2/>
      </div>
    </>

  )
          }
}

export default Home
