import React, { Component } from "react";
import './css/about.css'
import about1 from './images/about1.png'
import about2 from './images/about2.png'
import about3 from './images/about3.png'
import about4 from './images/about4.png'
import about5 from './images/about5.png'
import about6 from './images/about6.png'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import image1 from './images/image1.png'
import image2 from './images/image2.png'
import image3 from './images/image3.jpeg'
import WOW from "wowjs";
import { Container } from 'react-bootstrap';
import clinepage from './images/clinepage.png'
class AboutSection extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
 }
  render() {
    return (
      <>
        <div className='container about-section '>
          <div className=''>
            <h1 className='rota-hedg wow fadeInLeft ' data-wow-delay=".25s">Our Values</h1>
            <div className='row values-row '>
              <div className='col-lg-3 col-md-3 col-sm-6 d-flex justify-content-center'>
                <div className='values wow bounceInLeft' data-wow-delay=".25s">
                  <img src={about1} className='about-icon' alt='HONESTY' />
                  <h5 className='icon-name '>HONESTY</h5>
                </div>
              </div>

              <div className='col-lg-3 col-md-3 col-sm-6 d-flex justify-content-center'>
                <div className='values wow bounceInLeft' data-wow-delay=".35s">
                  <img src={about2} className='about-icon' alt='TRANSPARENCY' />
                  <h5 className='icon-name '>TRANSPARENCY</h5>
                </div>
              </div>


              {/* <div className='col-lg-2 col-md-4 col-sm-6 d-flex justify-content-center'>
                <div className='values wow bounceInLeft' data-wow-delay=".45s">
                  <img src={about3} className='about-icon' alt='' />
                  <h5 className='icon-name '>MENTORSHIP</h5>
                </div>
              </div>


              <div className='col-lg-2 col-md-4 col-sm-6 d-flex justify-content-center'>
                <div className='values wow bounceInRight' data-wow-delay=".55s">
                  <img src={about4} className='about-icon' alt='' />
                  <h5 className='icon-name '>COURTESY</h5>
                </div>
              </div> */}

              <div className='col-lg-3 col-md-3 col-sm-6 d-flex justify-content-center'>
                <div className='values wow bounceInRight' data-wow-delay=".45s">
                  <img src={about5} className='about-icon' alt='' />
                  <h5 className='icon-name '>INTEGRITY</h5>
                </div>
              </div>


              <div className='col-lg-3 col-md-3 col-sm-6 d-flex justify-content-center'>
                <div className='values wow bounceInRight' data-wow-delay=".35s">
                  <img src={about6} className='about-icon' alt='' />
                  <h5 className='icon-name '>EMPATHY</h5>
                </div>
              </div>


            </div>
          </div>
        </div>
         <Container>
            <Row className="mb-5">
                <Col xl={4} lg= {4} md={4} sm={12} className="">
                <h1 className=" rota-hedg">Our Associates</h1>
                  {/* <div className="about-v1">
                  </div> */}
<hr class="about-v1"/>

</Col>
                <Col xl={8} lg= {8} md={8} sm={12} className=" d-flex justify-content-end mb-5">
                  <img src={clinepage} alt="" className="w-100"/>
                </Col>

           </Row>
      
         </Container>
        {/* <Container className="mb-5">
          <h1 className='wow bounceInRight about-hed5 fw-bolder' data-wow-delay=".25s">Our Strategic Partners</h1>
          <Row>
            <Col lg={6} md={6} sm={12} className='mt-3'>
              <Row>
                <Col lg={6} md={12} sm={12}>
                  <img src={image1} className='about-img' alt='' />
                </Col>
                <Col lg={6} md={12} sm={12} className='wow fadeInLeft'>
                  <div className='about-tex1 d-flex flex-column'>
                    <h4 className='name'>Mr. Venkatachalam Iyer</h4>
                    <hr className='name-bar2' />
                    <p className='about-desc  text-left'>
                    Venkatachalam Iyer, the Co-Founder and CEO of BridgeEasy
                     Consultant LLP,  has over two decades of experience in the
                      banking industry. He has a wealth of knowledge in the financial 
                      sector. His last stint was as a Regional Head at a prominent private 
                      sector bank. He specialises in providing comprehensive financial solutions, 
                      catering to all your financial needs. Our primary focus is on facilitating 
                      MSME Loans, Startup Funding and Private Equity Funding.  In addition, we
                       offer a wide range of financial products and services, including Home
                        Loans, Personal Loans, Business Loans, Machinery Loans, and any other
                         financial assistance you may require. We have established strong
                          partnerships with private investors both in India and abroad, 
                          enhancing our ability to meet your financial goals effectively.
                    </p>
                  </div>
                </Col>

              </Row>
            </Col>
            <Col lg={6} md={6} sm={12} className='mt-3 wow fadeInRight' data-wow-delay=".25s">
              <Row>
                <Col lg={6} md={12} sm={12}>
                  <img src={image2} className='about-img' alt='' />

                </Col>
                <Col lg={6} md={12} sm={12} className='about-img' >
                  <div className='about-tex1 d-flex flex-column'>
                    <h4 className='name'>Ms. Chitra Iyer</h4>
                    <hr className='name-bar' />
                    <p className='about-desc text-left'>
                    Ms. Chitra Iyer holds the role of Co-Founder and Director 
                    at Bridgeeasy Consultant LLP, where she leverages her versatile 
                    skill set and extensive background to play a pivotal role in the
                     company's growth and prosperity. She is known for her proficiency
                      in effectively handling both clients and the company's operations.
                       Her diverse skills and extensive experience uniquely equip her to
                        ensure client satisfaction and oversee the operational aspects of 
                        the company, making her an integral part of Bridgeeasy Consultant LLP.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container> */}

        {/* <Container className="mb-5">
        <h1 className='wow bounceInRight about-hed5 fw-bolder mb-5' data-wow-delay=".25s">Our Strategic Partners</h1>
          <Row>
            <Col lg={4}>
            <Row  >
            <img src={image1} className='about-img' alt='' />
            </Row>
            <Row>
            <div className='about-tex1 d-flex flex-column'>
                    <h4 className='name'>Mr. Venkatachalam Iyer</h4>
                    <hr className='name-bar2' />
                    <p className='about-desc  text-left'>
                    Venkatachalam Iyer, the Co-Founder and CEO of BridgeEasy
                     Consultant LLP,  has over two decades of experience in the
                      banking industry. He has a wealth of knowledge in the financial 
                      sector. His last stint was as a Regional Head at a prominent private 
                      sector bank. He specialises in providing comprehensive financial solutions, 
                      catering to all your financial needs. Our primary focus is on facilitating 
                      MSME Loans, Startup Funding and Private Equity Funding.  In addition, we
                       offer a wide range of financial products and services, including Home
                        Loans, Personal Loans, Business Loans, Machinery Loans, and any other
                         financial assistance you may require. We have established strong
                          partnerships with private investors both in India and abroad, 
                          enhancing our ability to meet your financial goals effectively.
                    </p>
                  </div>
            </Row>
            </Col>
            <Col lg={4}>
            <Row  >
            <img src={image2} className='about-img' alt='' />
            </Row>
            <Row>
            <div className='about-tex1 d-flex flex-column'>
                    <h4 className='name'>Ms. Chitra Iyer</h4>
                    <hr className='name-bar' />
                    <p className='about-desc text-left'>
                    Ms. Chitra Iyer holds the role of Co-Founder and Director 
                    at Bridgeeasy Consultant LLP, where she leverages her versatile 
                    skill set and extensive background to play a pivotal role in the
                     company's growth and prosperity. She is known for her proficiency
                      in effectively handling both clients and the company's operations.
                       Her diverse skills and extensive experience uniquely equip her to
                        ensure client satisfaction and oversee the operational aspects of 
                        the company, making her an integral part of Bridgeeasy Consultant LLP.
                    </p>
                  </div>
            </Row>
            </Col>
            <Col lg={4}>
            <Row  >
            <img src={image3} className='about-img' alt='' />
            </Row>
            <Row>
            <div className='about-tex1 d-flex flex-column'>
                    <h4 className='name'>Mr. Lorem ipsum dolor sit amet.</h4>
                    <hr className='name-bar2' />
                    <p className='about-desc  text-left'>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorem earum rerum deserunt aperiam porro exercitationem fugit architecto explicabo, minima doloremque repudiandae soluta possimus inventore sunt sequi nisi neque autem necessitatibus dolorum. Facilis, ipsum sed inventore accusamus laudantium voluptatem architecto repellat asperiores culpa corporis saepe maiores?
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorem earum rerum deserunt aperiam porro exercitationem fugit architecto explicabo, minima doloremque repudiandae soluta possimus inventore sunt sequi nisi neque autem necessitatibus dolorum. Facilis, ipsum sed inventore accusamus laudantium voluptatem architecto repellat asperiores culpa corporis saepe maiores?
                    </p>
                  </div>
            </Row>
            </Col>
          </Row>
        </Container> */}


        <Container className="mb-5">
        <h1 className='wow bounceInRight rota-hedg mb-5' data-wow-delay=".25s">Our Strategic Partners</h1>
          <Row>
            <Col lg={4} sm={8}>
            <img src={image3} className='about-img d-flex justify-content-center' alt='' />
            </Col>
            <Col lg={4} sm={4}>
            <div className='about-tex1 d-flex flex-column'>
                    <h4 className='name'>Mr. Rupen Doctor</h4>
                    <hr className='name-bar2' />
                    <p className='about-desc  text-left'>
                    Mr. Rupen, the founder of Smart Gain, brings over 15 years of experience in the realms of banking and finance, making him a valuable strategic partner.
                    </p>
                  </div>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}
export default AboutSection