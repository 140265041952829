import React from 'react';
import 'animate.css'; 
import one from '../images/one.png'
import two from '../images/two.png'
export default function Testomonial2() {
  return (
    <div id='testimonials'>
       <div className='testi mb-5 mt-3'>
       <h1 className='text-center text-light  mb-5 animate__animated animate__fadeInLeft'>Testimonials</h1>
          <div className='container testimonial-boxes-wrapper animate__animated animate__fadeInRight'>
              <div className='row justify-content-center'>
                  <div className='col-lg-4 col-md-3 col-sm-12 mb-5'>
                     <div className='test-box'>
                         <h4 className='text-center'>Uma Mansharamani</h4>
                         <h6 className='text-center'>Co-Founder Tajurba Business Network & Manifestation</h6>
                         <img src={one} alt=''/>
                         <p  className='text-center'> Venkatachalam Iyer is a seasoned professional  in Loans and Finance industry
                          with his decades of experience working in the Banking Industry he  can easily 
                          identify best options suitable for clients and give right advice  for    clients benefits.
                           Highly Recommended.
                           <span className='d-flex justify-content-end'> <img src={two} alt=''/></span>
                           </p>
                         
                     </div>
                  </div>

                  <div className='col-lg-4 col-md-3 col-sm-12 mb-5'>
                  <div className='test-box'>
                      <h4 className='text-center'>Gaurav Sharma </h4>
                      <h6 className='text-center'>Co-founder Flexi Analyst</h6>
                      <img src={one} alt=''/>
                        <p  className='text-center'>I connected with Venkat sir in 2022 when we were
                       raising funds and the knowledge sir having in understanding business and finance
                        dynamics is amazing. Sir has helped us in raising funds and looking forward to do 
                        more things together.  <span className='m-4'><img src={two} alt=''/></span></p>
                  </div>
               </div>

               <div className='col-lg-4 col-md-3 col-sm-12 mb-5'>
               <div className='test-box'>
                   <h4 className='text-center'>Mr. M L Choudhary</h4>
                   <h6 className='text-center'>Reputed Samsung Mobile Dealer</h6>
                   <img src={one} alt=''/>
                   <p  className='text-center'> I want to express my gratitude to Bridgeeasy 
                   Consultant LLP for their outstanding support in securing my home loan. Their 
                   expertise and personalized approach made the process smooth and efficient.
                   <span className='m-4'><img src={two} alt=''/></span>
                   </p>
               </div>
            </div>
            <div className='col-lg-4 col-md-3 col-sm-12 mb-5'>
            <div className='test-box'>
                <h4 className='text-center'>Mr. Suresh Mansharamani</h4>
                <h6 className='text-center'>Co-founder Tajurba Business Network</h6>
                <img src={one} alt=''/>
                <p  className='text-center'> I'm truly impressed with your growth in the finance
                 industry. Your dedication, analytical skills, and commitment to ethical practices are 
                 remarkable. Keep pushing your boundaries, and I have no doubt you'll achieve great success. 
                 I'm here to support you every step of the way.
                 <span className='m-4'><img src={two} alt=''/></span>
                 </p>
            </div>
         </div>

         <div className='col-lg-4 col-md-3 col-sm-12 mb-5'>
            <div className='test-box'>
                <h4 className='text-center'>Mr. Prasad Bidkar</h4>
                <h6 className='text-center'>Partner - Finagal Multiserve LLP</h6>
                <img src={one} alt=''/>
                <p  className='text-center'> I wanted to transfer my existing Home Loan and I contacted Mr. Venkatachalam Iyer from Bridgeeasy Consultant LLP, a BNI Aspire member. Mr. Iyer not only helped me in finding best rates but also found ways to save a significant amount of Interest.  His expertise & dedication in finding best rates made the process smooth and cost effective. 
I am truly grateful for his service and would highly recommend Mr. Iyer to everyone looking to save on Loan Interest
                 <span className='m-4'><img src={two} alt=''/></span>
                 </p>
            </div>
         </div>
              </div>
          </div>
       </div>
    </div>
  )
}
