import React, { useEffect,useState   } from "react";
import WOW from "wowjs";
import './css/loan.css'
import { Col, Container, Row } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';  
import {Modal, Button} from 'react-bootstrap'; 
import "./css/Startup1.css"; 

function OverDraft() {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  })

  const [modalState, setModalState] = useState("close");  
      const handleShowModalOne = () => {
       setModalState("modal-one")
      }
      
      const handleShowModalTwo = () => {
       setModalState("modal-two")
      }
      
      const handleClose = () => {
       setModalState("close")
      }
    return (
      
      <div>
      
  <h3 className='text-left hdgstrup2 wow bounceInLeft'>Overdraft & Cash Credit Limit:</h3>
      <h3 className='text-left hdgstrup3 wow bounceInRight' data-wow-delay=".35s">A Brief Overview</h3>
      {/* <Container>
      <Row className='  wow bounceInLeft' >
          <Col lg={6} md={6} sm={12} className="d-flex justify-content-center overf1">
          <div className="smalcir color1"  onClick={handleShowModalOne}> 
               <h6 className="text-center mt-lg-3">1</h6>
             <h6 className="text-center">Overdraft</h6>  
         </div>
         <Modal show={ modalState === "modal-one"}  size='sm' className="bg-model">   
           <Modal.Body className="box-shadow" >  
           <h5 className=' text-center mt-lg-2'> 1 </h5>
           <h5 className=' text-center mt-lg-2'> Overdraft</h5>
           <p className='text-center mt-lg-3'>
              An overdraft is a credit facility that allows an individual or a business to withdraw more money from a bank account than 
              the available balance. It is a form of short-term borrowing where the account balance can go below zero, up to a predetermined
               limit. Overdrafts are usually used to cover temporary cash shortfalls, emergencies, or unexpected expenses. Interest is charged 
               only on the amount overdrawn, and overdrafts are typically repayable on demand or within a specified period.
              </p>
              </Modal.Body>  
                 <Modal.Footer>  
             <Button variant="outline-dark" onClick={handleClose}>Close </Button>  
           </Modal.Footer>  
            </Modal>
               <hr className='vertline3 wow bounceInRight'/>
          </Col>
         
          <Col lg={3} md={6} sm={12} className="d-flex justify-content-center overf2">
          <div className="smalcir color1"  onClick={handleShowModalTwo}>
          <h6 className="text-center mt-lg-3">2</h6>
             <h6 className="text-center">Cash Credit Limit</h6>  
         </div>
         <Modal show={modalState === "modal-two"}  size='sm' className="bg-model">   
           <Modal.Body className="box-shadow" >  
           <h5 className=' text-center mt-lg-2'>Cash Credit Limit:</h5>
              <p className='text-center mt-lg-3'>
              Cash credit limit is a type of credit facility provided to businesses by banks to meet their working capital requirements.
               It allows a business to withdraw funds up to a certain limit specified by the bank. The interest is charged on the amount utilized,
                and the repayment is flexible, depending on the business's cash flow. Cash credit limit is generally reviewed periodically and can 
                be renewed based on the borrower's creditworthiness.
              </p>
              </Modal.Body>  
          <Modal.Footer>  
             <Button variant="outline-dark" onClick={handleClose}>Close </Button>  
              </Modal.Footer>  
       </Modal>
          </Col>
        </Row>
      </Container> */}


      <Container>
        <Row>
        <Col lg={4} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column">
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right">Overdraft:</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-3"> Overdraft:</h3>
              <p className="snip__text text-left">
           
An overdraft is a short-term credit facility enabling individuals or businesses to withdraw beyond their bank balance, up to a set limit. Used for temporary cash shortages, emergencies, or unexpected expenses, interest applies only to the overdrawn amount. Repayment is typically on demand or within a specified period.
              </p>
            </figcaption>
           
          </figure>
            </Col>
            <Col lg={4} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column">
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right"> Cash Credit Limit:</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left "> Cash Credit Limit:</h3>
              <p className="snip__text text-left">
              Cash credit is a bank-provided credit facility for businesses, catering to their working capital needs. Businesses can withdraw funds within a specified limit, with interest charged on the utilized amount. Repayment is flexible, aligning with the business's cash flow. The limit is periodically reviewed and renewable based on creditworthiness.
              </p>
            </figcaption>
           
          </figure>
            </Col>
        </Row>
      </Container>



      <h5 className=' txt-blod text-left '>Key Points:</h5>
      <div className='container wow bounceInRight'>
        <div class="row justify-content-center d-flex">
          <div className='col-lg-4 col-md-4 col-sm-12 mt-5 d-flex justify-content-center'>
            <div className='rect1 d-flex justify-content-center'>
              <div className='rect2'>
                <p className='rect-para text-center'>
                  Overdraft and cash credit limit are short-term credit facilities provided by banks.
                </p>
              </div>
            </div>
          </div>
          <div className='col-lg-4 col-md-4 col-sm-12 mt-5 d-flex justify-content-center'>
            <div className='rect1 d-flex justify-content-center'>
              <div className='rect2'>
                <p className='rect-para text-center'>
                  Overdraft allows withdrawing more money than the available balance in a bank account, up to a predetermined limit.
                </p>
              </div>
            </div>
          </div>
          <div className='col-lg-4 col-md-4 col-sm-12 mt-5 d-flex justify-content-center'>
            <div className='rect1 d-flex justify-content-center'>
              <div className='rect2'>
                <p className='rect-para text-center'>
                  Cash credit limit is a credit facility for businesses to meet their working capital requirements.
                </p>
              </div>
            </div>
          </div>
          <div className='col-lg-4 col-md-4 col-sm-12 mt-5 d-flex justify-content-center'>
            <div className='rect1 d-flex justify-content-center'>
              <div className='rect2'>
                <p className='rect-para text-center'>
                  Interest is charged only on the amount utilized in both overdraft and cash credit limit.
                </p>
              </div>
            </div>
          </div>

          <div className='col-lg-4 col-md-4 col-sm-12 mt-5 d-flex justify-content-center'>
            <div className='rect1 d-flex justify-content-center'>
              <div className='rect2'>
                <p className='rect-para text-center'>
                  Repayment terms are flexible and depend on the borrower's creditworthiness.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className='srtuppara wow bounceInRight'>
        In conclusion, overdraft and cash credit limit are useful credit facilities that provide flexibility and convenience
        for individuals and businesses to manage their short-term financing needs. However, borrowers should carefully understand the terms
        and conditions, interest rates, and repayment terms associated with these credit facilities before availing them from a bank. It's always
        advisable to consult with a financial advisor or bank representative to make informed borrowing decisions.
      </p>
            </div>
    )
   
}
export default OverDraft;
