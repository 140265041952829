import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <div>
    <footer>
       <div className='container'>
           <div className='row'>
               <div className='col-lg-4 col-md-4 col-sm-12'>
                  <h5 className='mt-lg-4 mb-4'>About Us</h5>
                   <p>At Bridgeeasy, we ignite growth for startups and companies by 
                   fueling their financial journey with the capital they need tosoar. 
                   No more finance worries, no more searching from pillar to post. Our 
                   sole purpose is to empower the game changers, the trailblazers, the MSMEs
                    and individuals who refuse to be held back by limited access to funding.
                     With our expertise, we unleash endless possibilities, bringing their 
                     ambitions to life. Join us and ignite your financial journey today.</p>
               </div>
               <div className='col-lg-2 col-md-2 col-sm-6 '>
                  <h5 className='fw-bolder  mt-lg-4 mb-4'> Menu</h5>
                     <ul class = "list-unstyled">
                     <li className='mb-2'><Link to="" className='text-white'>Home</Link> </li>  
                     <li className='mb-2'><Link to="/about" className='text-white'>About Us</Link> </li>
                     <li className='mb-2'><Link to="/loan"  className='text-white'>Services</Link>  </li>
                     <li className='mb-2'><Link  to="/blog" className='text-white'>Blog</Link>  </li>
                     <li className='mb-2'><Link to="/contact" className='text-white'>Contact Us </Link></li>
                     </ul>
               </div>
               <div className='col-lg-2 col-md-2 col-sm-6 '>
               <h5 className='fw-bolder mt-lg-4 mb-4'> Useful Link</h5>
                     <ul class = "list-unstyled">
                     <li className='mb-2'><a  href='https://www.facebook.com/bridgeeasy'  className='text-white'>Facebook</a>  </li>
                     <li className='mb-2'> <a href='https://www.linkedin.com/company/bridgeeasyconsultancy/' className='text-white'>  Linked In  </a></li>
                     <li className='mb-2' ><a href='https://wa.me/8459851404?text=I%20need%20help%20with%20my%20finance/portfolio' className='text-white'>What's App</a>  </li>
                     <li className='mb-2'>Twitter  </li>
                     <li className='mb-2'>Youtube </li>
                     </ul>
               </div>
               <div className='col-lg-4 col-md-4 col-sm-12'>
                  <h5 className=' mt-lg-4 mb-4 contcus'>Contact Us</h5>
                  <ul style={{padding:"0px"}}>
                  <li>Regd. Office : C-101, Naren Hills, Near Ruby Hall Clinic, Azadnagar, Pune - 411022 </li>
                   <li> Call : 99754 22229</li>
                   {/* <li ><a href="mailto:venkat@bridgeeasy.in" className='text-white' >Email : support@bridgeeasy.in </a></li>  */}
                  </ul>
               </div>
           </div>
       </div>

    </footer>
    <div className='text-center copyright '>
    <p>Crafted by Endeavour Marketing</p>
    </div>

    </div>
  )
}
