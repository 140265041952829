import React from 'react'
import { Container } from 'react-bootstrap'
import "./services2.css"

const ServicesCard = ({data}) => {



  return (
    
    <div className='services-card'>
      <Container>
   <div className="service-card-img-wrapper ">
<img src={data.serviceImg} alt="service-img" />
   </div>
   <div className="service-card-detail">
   <h3 className='fw-bolder card-head'>{data.serviceName}</h3>
   <p className='card-para'>{data.serviceDetail}</p>
   </div>
   </Container>
    </div>
  )
}

export default ServicesCard