import './App.css';
import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
// import Contact from './components/Contact';
import Contact2 from './Components3/Contact2';
import Footer from './components/Footer';
import Client from './components/Client';
import Services from './components/Services';
import Blog from './components/Blog';
import Loan from './components/Loan';
import Startup from './components/Startup';
import GovetLoas from './components/GovetLoas';
import StrupFinding from './components/StrupFinding';
import PrivindingEquity from './components/PrivindingEquity';
import OverDraft from './components/OverDraft';
import Finacial from './components/Finacial';
import ScrollButton from './components/ScrollButton';
import SocialMedia from './components/SocialMedia';

import LoginForm from './components/LoginForm';
import BlogInput from './components/BlogInput';
import StartUpFunding1 from './components/StartUpFunding1';
import StartUp1 from './components/Startup1';
import GovrtLoan1 from './components/GovrtLoan1';
import PrivateEquity1 from './components/PrivateEquity1';
import Finacial1 from './components/Finacial1';


function App() {
  const [datas, setDatas] = useState()
  // setVal(data)
  const setVal = (data) => {
    console.log(data, "dsfds");
    setDatas(data)
  }
  return (
    <div className="App">

      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/blog" element={<Blog values={datas} />} />
          <Route path="/contact" element={<Contact2 />} />
          <Route path="/loan" element={<Loan />} />
          <Route path="/startup" element={<StartUp1 />} />
          <Route path="/govetLoas" element={<GovrtLoan1 />} />
          <Route path="/strupFinding" element={<StartUpFunding1 />} />
          <Route path="/privindingEquity" element={<PrivateEquity1 />} />
          <Route path="/overDraft" element={<OverDraft />} />
          <Route path="/finacial" element={<Finacial1 />} />
          {/* <Route path="/blogedit" element={<BlogInput data={setVal} />} />*/}
          {/*           <Route path="/bloglogin" element={<LoginForm />} /> */}

        </Routes>
      {/* <ScrollButton /> */}  
       {/* <SocialMedia /> */} 
        <Footer />
      </Router>

    </div>
  );
}

export default App;
