import React from "react";
import "../Components3/profile/profile.css";
import firstBlockImg from "../images/Rectangle 196.png";
import lastBlockImg from "../images/Rectangle 195.png";
import secondBlockImg from "../images/Reactangle1122.jpg"
import 'animate.css'; 

const About3 = () => {
  return (
    <div className="profile-section mb-5" id='profile'>
      {/* <h2 className="profile-head animate__animated animate__fadeInLeft">Profile</h2> */}
      <h1 className="first-profile-block  animate__animated animate__fadeInLeft">Our Founder and CEO</h1>
      <div className="profile-blocks">
        
        <div className="first-profile-block image-block animate__animated animate__fadeInLeft ">
          <img src={firstBlockImg} alt="ownerImg" />
        </div>
        <div className="second-profile-block profile-block  animate__animated animate__fadeInRight ">
        <p style={{fontWeight:'600',letterSpacing:'1px'}}> Venkatachalam Iyer</p>
          <h3 className="mb-3"> Financial Consultant </h3>
          {/* <p style={{fontWeight:'600',letterSpacing:'1px'}}>Financial Consultant</p> */}
          <p className="block-content d-flex flex-wrap align-content-center ">
            Venkatachalam Iyer is a seasoned financial consultant with a
            distinguished career spanning over 25 years in the banking industry.
            In his last stint as a Vice President and Project Team Leader,
            Venkat has excelled in financial analysis, providing advisory
            services to High-Net-Worth Individuals (HNIs),risk management,
            business development, relationship management, compliance
            management, budgeting and resource allocation, credit approvals.
            This experience has uniquely positioned him to master the art of
            raising capital through debt and equity. Venkat serves diverse
            clients, including private investors, angel investors, family
            offices, HNIs, private lenders, commercial banks, and NBFCs.
          </p>
        </div>
        <div className="third-profile-block profile-block animate__animated animate__fadeInRight ">
          <h3 className="mb-3">Expertise and Vision</h3>
          <p  className="block-content d-flex flex-wrap align-content-center">
            With extensive experience in the corporate financial sector,
            Venkatachalam Iyer distinguishes himself as a financial strategist.
            His profound knowledge in financial advisory, funding advisory, risk
            assessment, asset allocation, wealth management, and personal
            finance is complemented by his exceptional leadership and
            proficiency in optimizing operations and ensuring rigorous
            compliance in the banking sector.
          </p>
        </div>
      </div>
      <div className="profile-blocks ">
        <div className="fourth-profile-block profile-block animate__animated animate__fadeInLeft">
          <h3 className="mb-5">A Distinguished Network</h3>

          <p  className="block-content d-flex flex-wrap align-content-center">
            Venkatachalam Iyer has meticulously cultivated a remarkable network
            of partnerships with top-tier banks, esteemed NBFCs, and influential
            private investors. These esteemed connections empower him to provide
            his clients with unparalleled access to a broad spectrum of
            financial resources and investment opportunities.
          </p>
        </div>
        <div className="fifth-profile-block profile-block animate__animated animate__fadeInRight">
          <h3>Your Financial Partner</h3>
          <p  className="block-content d-flex flex-wrap align-content-center">
            Venkat is the pivotal force for delivering tailored financial
            solutions and driving strategic financial growth for clients across
            the spectrum.
          </p>
        </div>
        <div className="sixth-profile-block image-block mb-5">
          <img src={lastBlockImg} alt="ownerImg" />
        </div>
      </div>
      
      <h1 className="first-profile-block  animate__animated animate__fadeInLeft mb-5">Our Co-Founder and Director</h1>
      <div className="profile-blocks">
        <div className="seventh-profile-block image-block animate__animated animate__fadeInLeft ">
          <img src={secondBlockImg} alt="ownerImg" className="secondA" />
        </div>
        <div className="eighth-profile-block profile-block  animate__animated animate__fadeInRight p-5 ">
        <p style={{fontWeight:'600',letterSpacing:'1px'}}>Ms. Chitra Iyer</p>
          <h3 className="mb-3">Co-Founder and Director  </h3>
          {/* <p style={{fontWeight:'600',letterSpacing:'1px'}}>Financial Consultant</p> */}
          <p className="block-content d-flex flex-wrap align-content-center  ">
          Ms. Chitra Iyer holds the role of Co-Founder and Director at Bridgeeasy Consultant LLP, where she leverages her versatile skill set and extensive background to play a pivotal role in the company's growth and prosperity. She is known for her proficiency in effectively handling both clients and the company's operations. Her diverse skills and extensive experience uniquely equip her to ensure client satisfaction and oversee the operational aspects of the company, making her an integral part of Bridgeeasy Consultant LLP.
          </p>
        </div>
        {/* <div className="third-profile-block profile-block animate__animated animate__fadeInRight ">
          <h3 className="mb-3">Expertise and Vision</h3>
          <p  className="block-content d-flex flex-wrap align-content-center">
            With extensive experience in the corporate financial sector,
            Venkatachalam Iyer distinguishes himself as a financial strategist.
            His profound knowledge in financial advisory, funding advisory, risk
            assessment, asset allocation, wealth management, and personal
            finance is complemented by his exceptional leadership and
            proficiency in optimizing operations and ensuring rigorous
            compliance in the banking sector.
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default About3;
