import React from "react";
import "../assets/css/About2.css";
import finance from "../images/Rectangle 172.png"
import 'animate.css'; 

const About2 = () => (
  <div className='about-section mt-5 container' id='about'>
  <section id='#about' >
  <div className='container'>
          <div className='row '>
             <div className='col-lg-6 col-md-6 col-sm-12 m-auto  '>
             <h2 className="fw-bolder animate__animated animate__fadeInLeft">About Us</h2>
             <p className='text-left text-dark animate__animated animate__fadeInLeft ' >
             At Bridgeeasy, we ignite growth for startups and companies by fueling their financial 
             journey with the capital they need to soar. No more finance worries, no more searching
             from pillar to post. Our sole purpose is to empower the game changers, the trailblazers,
              the MSMEs and individuals who refuse to be held back by limited access to funding. With 
              our expertise, we unleash endless possibilities, bringing their ambitions to life. Join us 
              and ignite your financial journey today.
             </p>
             </div>

             <div className='col-lg-6 col-md-6 col-sm-12'>
                      <div className='box1 mb-5 animate__animated animate__fadeInRight'>
                      <h2 className='fw-bolder'>Vision</h2>
                      <p className="text-left">To be the driving force for MSMEs, unlock their full potential by pioneering 
                      accessible, affordable and innovative finance solutions</p>
                      </div>

                      <div className='box2 mb-5 animate__animated animate__fadeInRight'>
                         <h2 className='text-right fw-bolder'>Mission</h2>
                           <p className='text-right'> At Bridge Easy, we simplify capital access
                          and offer collateral-free solutions to MSMEs. Our flexible eligibility criteria
                              make financing more accessible and we provide assistance with government-backed 
                               Credit Guarantee schemes. We prioritise efficient cash flow management and offer
                           equity funding based on your business needs.
                              </p>
                      </div>
                </div>
          </div>
        </div>

        <h3 className='text-center busines-headg animate__animated animate__fadeInLeft'>Empowering Your Business Journey</h3>
        <p className='text-center busines-para animate__animated animate__fadeInRight'>
        Embarking on the journey of business expansion and growth can often be a daunting and uncertain
         endeavor, requiring intricate decision-making. At Bridge Easy, we genuinely empathise with the
          distinctive challenges and potential opportunities that every business faces along this transformative 
          path. Our commitment is to empower you with precisely tailored financial solutions, ensuring you can
           navigate these challenges with confidence and seize the opportunities that lie ahead.
        </p>

        <div className='row'>
          <div className=" col-md-4 col-md-4 col-sm-12">
          <img src={finance} alt='' className='finace-img mb-2 animate__animated animate__fadeInLeft'/>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12  animate__animated animate__fadeInRight ">
          <div className='row'>

       <div className='col-lg-4 col-md-4 col-sm-12'>
               <div  className='green-box'>
              <h5 className='text-center'>Financing  Solutions</h5>
              <p className='text-center' >Unlock capital for your business's expansion 
              needs with tailored financing solutions</p>
          </div>
      </div>
      <div className='col-lg-4 col-md-4 col-sm-12'>
      <div  className='green-box'>
        <h5 className='text-center'>Competitive  Rates</h5>
        <p className='text-center' >Manage your finances effectively with our competitive
         interest rates.</p>
      </div>
  </div>

<div className='col-lg-4 col-md-4 col-sm-12'>
    <div  className='green-box'>
      <h5 className='text-center'>Expert  Guidance</h5>
    <p className='text-center' >Receive expert guidance for informed financial
     decisions tailored to your goals.</p>
  </div>
</div>
</div>

<div className='row mt-lg-3 mb-2'>
<div className='col-lg-4 col-md-4 col-sm-12'>
    <div  className='blue-box'>
        <h5 className='text-center'> Cash Flow Optimization</h5>
        <p className='text-center' >Optimize cash flow for operational needs and future growth.</p>
    </div>
</div>

<div className='col-lg-4 col-md-4 col-sm-12'>
  <div  className='blue-box'>
    <h5 className='text-center'>Market  Success</h5>
    <p className='text-center' >Navigate market competition successfully with
     our strategic partnership.</p>
  </div>
</div>

<div className='col-lg-4 col-md-4 col-sm-12'>
<div  className='blue-box'>
  <h5 className='text-center'>Compliance Made Easy</h5>
<p className='text-center' >Simplify regulatory compliance, freeing you to focus
 on your business activities.</p>
</div>
</div>
</div>
          </div>
         </div>
  </section>
  </div>
);

export default About2;
