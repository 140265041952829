import React from 'react'
import "../assets/css/Contact2.css"
import 'animate.css'; 

export default function Contact() {
  return (
   <div >
    <div id='contacts '>
    <form class="form" role="form" method="post" action="contact.php">
        <div className='container contact-section-container mt-lg-5'>
        <h3 className='mb-4 fw-bolder text-left animate__animated animate__fadeInLeft'>We Are Here To Help You !</h3>
        <div className='row animate__animated animate__fadeInRight '>
           <div className='col-lg-6 col-md-6 col-sm-12 mb-4'>
              <input type="text" class="form-control" placeholder="Name"  name="fname" Id="fname"/>
           </div>
       <div className='col-lg-6 col-md-6 col-sm-12 mb-4'>
           <input type="text" class="form-control" placeholder="Name of the Company" name='namcomp' Id="namcomp"/>
        </div>
        <div className='col-lg-6 col-md-6 col-sm-12 mb-4'>
        <input type="phone" class="form-control" placeholder="Phone No" name="phone" Id="phone"/>
     </div>
         
     <div className='col-lg-6 col-md-6 col-sm-12 mb-4'>
          <input type="email" class="form-control" placeholder="Email Id"  name="email" Id="email" />
    </div>

  <div className='col-lg-12 col-md-12 col-sm-12 mb-4'>
      <input type="text" class="form-control" placeholder="Nature of Business"  name="naturebus" Id="naturebus" />
 </div>
<div className='col-lg-12 col-md-12 col-sm-12 mb-4'>
      <input type="text" class="form-control" placeholder="Last Year Turnover"  name="lyturnover" Id="lyturnover" />
</div> 
 <div className='col-lg-12 col-md-12 col-sm-12 mb-4'>
      <input type="text" class="form-control" placeholder="No. of Years in Business" name="noofyears" Id="noofyears" />
  </div>
  <div className='col-lg-12 col-md-12 col-sm-12 mb-4'>
   <input type="text" class="form-control" placeholder="Desired Services" name='desirserv' Id="desirserv"  />
</div>
        </div>
        <div className='d-flex justify-content-end mb-4'>
        <button type="submit" name="submit" value="Submit" class="btn btn-outline-success animate__animated animate__bounce " >Submit</button>
     </div>
     {/* <div className='contactus-map mb-5'>
     <iframe width="100%" height="200" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=200&amp;hl=en&amp;q=Office%2032,%20Bhosale%20Shinde%20Arcade,%20Next%20to%20Deccan%20Bus%20stop,%20Deccan%20Pune.+(My%20Business%20Name)&amp;t=&amp;z=18&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/population/">Find Population on Map</a></iframe>
</div>*/}
        </div> 
        <a href="https://wa.me/8459851404?text=I%20need%20help%20with%20my%20finance/portfolio" class="floating" target="_blank">
        <i class="fa fa-whatsapp fab-icon"></i>
        </a>
        </form>
        </div>
       
        </div>
  )
}
