import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./blog2.css"
import Blogcard from "./Blogcard";
import { blogData } from "./blogdata";
export default function Blog2() {
  return (
    <section className="blog-section" id="blogs">
     <h1 className="blog-head mb-5 animate__animated animate__fadeInLeft">Blog</h1>
    
     <div className="blog-slider-wrapper">
     <SimpleSlider/>
     </div>

    </section>
  );
}

export function SimpleSlider() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,         // Enable autoplay
    autoplaySpeed: 1500,    // Set autoplay speed in milliseconds (e.g., 3000ms or 3s)
    nextArrow: <h2>Next</h2>,
    prevArrow: <h2>Prev</h2>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {
       blogData.map((data,index)=>{
        return  <Blogcard data={data} />
      })
      }
    </Slider>
  );
}
