import React from 'react'
import "./blog2.css"
const Blogcard = ({data}) => {
  return (
    <div className='blog-card'>
       <a href={data.links}>
      <div className="blog-card-img-wrapper">
        <img src={data.blogImg} alt="service-img" />
      </div>
        <div className="blog-card-detail">
          <p className='card-para text-blue' >{data.blogDetail}</p>
        </div>
        </a>
     </div>
  )
}

export default Blogcard