import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./css/Startup1.css";


function StartUp1() {
  return (
    <div className='mb-5'>
      <Container>
        <Row>
        <h1 className="mt-5 d-flex justify-content-center ">Loans</h1>
        <p className=" intro justify-content-center p-3">
        When ever the customer comes to our office or online we first assess their need and advise accordingly with a proper solution to them so they go with clarity in their mind as whuch loan they should apply.
        </p>
        </Row>
        <Row>
            
            <Col lg={4} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column">
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right">Business Loan</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-4"> Business Loan</h3>
              <p className="snip__text text-left">
              Elevate your business with Bridgeeasy. Our business loans offer flexibility, competitive terms, and swift approvals. Approach us for tailored financial support that propels your business forward.
              </p>
            </figcaption>
           
          </figure>
            </Col>
            <Col lg={4} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column">
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right">Home Loan</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-4"> Home Loan</h3>
              <p className="snip__text text-left">
              At Bridgeeasy, we're here to turn your homeownership dreams into reality. With competitive rates and personalized service, our home loan solutions make the journey to your dream home smooth and stress-free.
              </p>
            </figcaption>
           
          </figure>
            </Col>
            <Col lg={4} md={6} sm={12} className="d-flex justify-content-center align-items-center flex-column">
            <figure className="snip">
            
            <div className="snip__image snip-container d-flex" alt="card">
              <span className="title text-right">Personal Loan</span>
            </div>
            <figcaption className="snip__figcaption">
              <h3 className="snip__title text-left my-4">Personal Loan</h3>
              <p className="snip__text text-left">
              Unlock financial freedom with Bridgeeasy. Our personal loans feature competitive rates and a hassle-free process, tailored to meet your unique needs. Approach us for a swift journey to achieve your financial goals.
              </p>
            </figcaption>
          </figure>
            </Col>
       
           
        </Row>
      
      </Container>



    </div>
  )
}

export default StartUp1
