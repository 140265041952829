import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./services2.css"
import ServicesCard from "./ServicesCard";
import { servicesData } from "./servicesdata";
import 'animate.css'; 

export default function Services2() {
  return (
    <section className="services-section" id="services">
     <h1 className="services-head  animate__animated animate__fadeInRight">Services</h1>
     <p className="services-para  animate__animated animate__fadeInLeft">Where Financial Expertise Meets Your Needs</p>
     <div className="services-slider-wrapper">
     <SimpleSlider/>
     </div>


  
    </section>
  );
}

export function SimpleSlider() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1, // Change this to 1
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 1500,
    nextArrow: <h2>Next</h2>,
    prevArrow: <h2>Prev</h2>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1, // Change this to 1
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1, // Change this to 1
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {servicesData.map((data, index) => {
        return <ServicesCard data={data} key={index} />;
      })}
    </Slider>
  );
}