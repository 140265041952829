
// import React from 'react'
import logo from './images/logo.png'
// export default function Navbar() {
//   return (


//     <nav className="navbar navbar-expand-lg">
//       <a className="navbar-brand" href="/">
//         <img src={logo} alt="logo" className='logo' />
//       </a>
//       <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
//         <span className="navbar-toggler-icon"></span>
//       </button>
//       <div className="collapse navbar-collapse justify-content-center" id="navbarText">
//         <div className="navbar-nav">
//           <a className="nav-link " aria-current="page" href="/">HOME</a>
//           <a className="nav-link" href="/about">ABOUT US</a>
//           <a className="nav-link" href="/client">CLIENTS</a>
//           <a className="nav-link" href="/services">OUR SERVICES</a>
//           <a className="nav-link" href="/blog">BLOGS</a>
//           <a className="nav-link" href="/contact">CONTACT US</a>
//         </div>
//       </div>
//     </nav>
//   )
// }
import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
//import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
function NavbarE() {
  return (
    <div className='container'>
    <Navbar collapseOnSelect expand="lg" bg="" variant="">
    <Container fluid>
    <Navbar.Brand href="/">
      <img src={logo} alt="logo" className='logo' />
    </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ml-auto">
              <Link to="/" className='navlink'>HOME</Link>
              <Link to="/about" className='navlink'>ABOUT US</Link>
              <Link to="/loan" className='navlink fw-bold'>OUR SERVICES</Link>
              <Link to="/blog" className='navlink fw-bold'>BLOGS</Link>
              <Link to="/contact" className='navlink'>CONTACT US</Link>
              </Nav>
          </Navbar.Collapse>
          </Container>
      </Navbar>
      </div>

  );
}

export default NavbarE;






