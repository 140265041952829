import React, { useState} from 'react';
import StrupFinding from './StrupFinding';
import PrivindingEquity from './PrivindingEquity';
import OverDraft from './OverDraft';
import GovetLoas from './GovetLoas';
import Startup from './Startup';
import Finacial from './Finacial';
import bloan from './images/bloan.png';
import ploan from './images/ploan.png'
import hloan from './images/hloan.png'
import hbloan from './images/hbloan.png'
import hhloan from './images/hhloan.png'
import hploan from './images/hploan.png'
import ModelFloat from './ModelFloat';
import Services from './Services';
import StartUpFunding1 from './StartUpFunding1';
import StartUp1 from './Startup1';
import GovrtLoan1 from './GovrtLoan1';
import PrivateEquity1 from './PrivateEquity1';
import Finacial1 from './Finacial1';


export default function Loan() {
  const [show, setShow] = useState(false)
  const [show1, setShow1] = useState(false)
  const [show2, setShow2] = useState(false)

  const getImg = (img1) => {
    console.log(img1);
    if (img1.target.className ==='imgbloan') { setShow(true) }
    else if (img1.target.className === 'imghloan') { setShow1(true) } else {
      setShow2(true)
    }
  }
  const setImg = (img1) => {
    if (img1.target.className === 'imgbloan') { setShow(false) }
    else if (img1.target.className === 'imghloan') { setShow1(false) } else {
      setShow2(false)
    }
  }
  return (
    <div className='container-fluid mb-5'>
 <Services/>

      



      
      
      {/* <Startup />
      <GovetLoas />
      <StrupFinding />
      <PrivindingEquity />
      <OverDraft />
      <Finacial />
      <ModelFloat /> */}
      <a href="https://wa.me/8459851404?text=I%20need%20help%20with%20my%20finance/portfolio" class="floating" target="_blank">
      <i class="fa fa-whatsapp fab-icon"></i>
      </a>
    </div>
  )
}

